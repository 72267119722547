.menu-item-cuenta {
    font-size: 1rem;
    text-transform: uppercase;
    padding: 30px 0;
    width: 80%;
    border-bottom: #7A7A7A solid 1px;
    display: block;
}
.menu-item-cuenta a:first-of-type{
    padding-top: 60px;
}
.menu-item-cuenta a{
    cursor: pointer;
}
.menu-item-cuenta a:hover {
    color:#fff;
    border-bottom: #fff solid 1px;
}
.menu-cuenta .active{
    color: #ffffff;
}