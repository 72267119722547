.modalsettings_confirmation h2{
    color: white;
    font-size: 28px;
    margin-bottom: 1rem;
    font-weight: 500;
}
.modalsettings_confirmation p{
    color: #999999;
    font-weight: 400;
    font-size: 15px;
    margin-top: 0;
    margin-bottom: 20px;
    line-height: 1rem;
}
.modalsettings_confirmation p:last-of-type{
    margin-bottom: 30px;
}
.modalsettings_confirmation button{
    padding: 1.2rem;
    width: 100%;
    border:1px solid #999999;
    border-radius: 5px;
    background-color: #0B0D1A;
    color: #999999;
    text-transform: uppercase;
    font-size: 0.95rem;
    font-weight: 400;
    letter-spacing: 1px;
    text-align: center;
    cursor: pointer;
}