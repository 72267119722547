.baseModalRegistroPresencial {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 8, .95);
    position: fixed;
    display: none;
    justify-content: center;
    align-items: flex-start;
    z-index: 999;
    position: absolute;
    top:0;
    left: 0;
}

.baseModalRegistroPresencial.open{
    display: flex;
}

.baseModalRegistroPresencial .modalBody{
    max-width: 60%;
    margin-top: 200px;
}

.baseModalRegistroPresencial .modalBody h2{
    color:#fff;
}

.baseModalRegistroPresencial .modalBody .titulo{
    font-size: 26px;
    line-height: 32px;
    margin-bottom: 20px;
}

.baseModalRegistroPresencial .modalBody .tituloheader{
    text-align: left;
    font-size: 36px;
    line-height: 42px;
    margin-bottom: 20px;
    color: #fff;
}

.baseModalRegistroPresencial .modalBody.modalLogin .form-group{
    margin-bottom: 15px;
}

.baseModalRegistroPresencial .helperLogin{
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.baseModalRegistroPresencial .helperLogin .small{
    cursor: pointer;
}


.baseModalRegistroPresencial .small{
    font-size: 12px;
}

.baseModalRegistroPresencial label.small{
    font-size: 14px;
}

.inputText{
    height: 60px;
    background: #707070;
    border-radius: 5px;
    border: none;
    width: calc(100% - 30px);
    margin: 10px 0;
    color: #fff;
    font-size: 16px;
    padding: 0 15px;
}

.labelInput{
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
    color: #FFF;
}
.modalBody .modalInscripcionbody{
    position: relative;
    max-width: 480px;
    max-height: 70vh;
    padding: 50px;
    background-color: #0B0D1A;
    border:1px solid #707070 ;
    border-radius: 5px;
    box-sizing: content-box;
    margin: 0 15px;
}
.modalInscripcionbody .titulo {
    font-size: 28px;
    margin-bottom: 35px;
}
.modalInscripcion p{
    color: white;
    font-size: 18px;
    margin-bottom: 20px;
    line-height: 22px;
}
.modalInscripcion span{
    margin-bottom: 40px;
    font-size: 18px;
    line-height: 22px;
}
.modalInscripcion .btn.transparente{
    width: 100%;
    padding: 15px;
}