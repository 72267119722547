.stand{
    display: grid;
    grid-template-columns: 75% 25%;
    background: linear-gradient(#000000, #222232);
    min-height: 100vh;
    padding:2rem 6rem;
}
.stand_main{
    margin-right: 2rem;
}
.stand_main_banner{
    margin-bottom: 45px;
}
.stand_main_banner img{
    width: 100%;
}
.stand_main_texto{
    background-color: #262632;
    border-radius: 5px;
    padding: 1.5rem;
    margin-bottom: 45px;
}
.stand_main_texto h2{
    font-size: 1.3rem;
    color: #DDDDDD;
    margin-bottom: 25px;
}
.stand_main_texto h2 span:before{
    content: ".";
    color: white;
    vertical-align: 13%;
    margin:0 0.4rem;
}
.stand_main_texto h2 span{
    color:#00E1FA;
}
.stand_main_texto h3{
    font-size: 1.6rem;
    color: white;
    margin-bottom: 25px;
}
.stand_main_texto p{
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 25px;
}
.stand_main_texto .btn-enviar{
    width: 300px;
    background-color: #54A6F8;
    color: white;
    padding: 0.8rem 1rem;
    text-transform: uppercase;
    font-weight: 500;
    border:none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 25px;
    margin-bottom: 25px;
}
.stand_main_next{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}
.stand_main_next_item h4{
    color: white;
    font-size: 1rem;
    background-color: #262632;
    padding: 1rem;
    border-radius: 5px 5px 0 0;
    margin-bottom: 0;
}
.stand_main_next_item img{
    width: 100%;
}
.stand_main_next_item:last-of-type{
    margin-right: 0;
}
.stand_main_next_item_tag{
    position: absolute;
    top: 5.5rem;
    left: 0.5rem;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 0.3rem;
    text-transform: uppercase;
    font-size: 0.7rem;
    color: white;
}
.stand_main_next_item_tag p{
    margin: 0;
}
.stand_lateral_caja{
    background-color: #262632;
    border-radius: 10px;
    margin-bottom: 45px;
}
.stand_lateral_logo{
    display: flex;
    justify-content: center;
    align-items: center;
}
.stand_lateral_logo img{
    width: 100%;
   padding: 2rem;
}
.stand_lateral_logo.stand_responsive{
    display: none;
}
.stand_lateral_imagen{
    display: flex;
    justify-content: center;
    align-items: center;
}
.stand_lateral_imagen div{
    width: 100%;
}
.stand_lateral_imagen a{
    width: 100%;
}
.stand_lateral_imagen img{
    width: 100%;
}
.stand_lateral_imagen video{
    width: 100%;
}
.stand_video.stand_responsive{
    display: none;
}
.stand_lateral_caja h3{
    color: white;
    font-size: 1.2rem;
    background-color: #262632;
    padding: 1rem;
    border-radius: 10px 10px 0 0;
}
.stand_lateral_caja_contacto_texto{
    padding: 30px;
}
.stand_lateral_caja_contacto_texto h4{
    color: white;
    font-size: 1.2rem;
    margin:0;
    margin-bottom: 0.3rem;
}
.stand_lateral_caja_contacto_texto h5{
    font-size: 0.8rem;
    margin:0;
    margin-bottom: 0.3rem;
}
.stand_lateral_caja_contacto_texto h6{
    font-size: 0.9rem;
    margin:0;
    margin-bottom: 1rem;
}
.stand_lateral_caja_contacto_texto .btn{
    width: 100%;
    background-color: #54A6F8;
    color: white;
    padding: 0.8rem 1rem;
    text-transform: uppercase;
    font-weight: 500;
    border:none;
    border-radius: 5px;
    cursor: pointer;
}
@media (max-width: 1200px){
    .stand{
        padding: 2rem;
    }
}
@media (max-width: 980px) {
    .stand_main_next{
        grid-template-columns: auto auto;
        gap: 40px;
    }
    .stand{
        grid-template-columns: auto;
    }
    .stand_main{
        margin-right: 0;
    }
    .stand_lateral{
        display: flex;
        justify-content: space-between;
    }
    .stand_lateral_caja{
        margin-right: 0;
        width: 32%;
    }
    .stand_lateral_logo{
        display: none;
    }
    .stand_lateral_logo.stand_responsive{
        display: flex;
        margin-bottom: 40px;
    }
    .stand_lateral_caja{
        width: 100%;
        margin-right: 0;
        margin-bottom: 1rem;
    }
    .stand_main_banner{
        margin-bottom: 40px;
    }
    .stand_main_texto{
        margin-bottom: 40px;
    }
    .stand_main{
        margin-bottom: 40px;
    }
    .stand_video{
        display: none;
    }
    .stand_video.stand_responsive{
        display: block;
        margin-bottom: 40px;
    }
}
@media (max-width: 680px){
    .stand_main_texto .btn-enviar{
        width: 100%;
    }
    .stand_main_next{
        display: flex;
        flex-direction: column;
    }
    .stand_main_next_item{
        width: 100%;
    }
    .stand_lateral{
        display: flex;
        flex-direction: column;
    }
}
