.baseModal {
    width: 100%;
    height: 100vh;
    background-color: rgba(11, 13, 26, .80);
    position: fixed;
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.baseModal.open{
    display: flex;
}

.modalBody{
    position: relative;
    max-width: 480px;
    max-height: 70vh;
    padding: 50px;
    background-color: #0B0D1A;
    border:1px solid #707070 ;
    border-radius: 5px;
    box-sizing: content-box;
    margin: 0 15px;
}
.modalBody .modalLogin_cerrar{
    position: absolute;
    top:-28px;
    right: 0;
    font-size: 0.65rem;
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;
}

.modalBody .titulo{
    font-size: 26px;
    line-height: 32px;
    margin-bottom: 35px;
    font-weight: 500;
}

.modalBody .tituloheader{
    text-align: center;
    font-size: 36px;
    line-height: 42px;
    margin-bottom: 35px;
}
.modalLogin_logo{
    width: 200px;
    margin: 0 auto 40px auto;
}
.modalBody.modalLogin .form-group{
    margin-bottom: 15px;
}
.modalBody.modalLogin .form-group:nth-of-type(3){
    margin-bottom: 0px;
}
.recuerdame{
    margin-bottom: 15px;
}
.recuerdame input{
    margin-right: 5px;
}
.modalBody .labelInput{
    color: #DDDDDD ;
    font-weight: 500;
    margin-bottom: 15px;
    font-size: 18px;
}
.AVAST_PAM_loginform label{
    color: white;
    font-size: 18px;
}

.helperLogin{
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.helperLogin .small{
    cursor: pointer;
}
.modalBody .texto_popup{
    color:#DDDDDD;
    font-size: 18px;
    margin-bottom: 30px;
}
.btn_popup{
    width: 100%;
    padding: 20px;
}
.modalBody .titulo_popup_pass{
    font-size: 28px;
}
.modalBody .helperLogin{
    margin-top: 30px;
}


.small{
    font-size: 12px;
}

label.small{
    font-size: 14px;
}

.inputText{
    height: 60px;
    background: #707070;
    border-radius: 5px;
    border: none;
    width: calc(100% - 30px);
    margin: 10px 0;
    color: #fff;
    font-size: 16px;
    padding: 0 15px;
}

.labelInput{
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
    color: #FFF;
}
.boton_iniciarsesion{
    background-color: #bbf1a0;
    width: 100%;
    border-radius: 5px;
    border: none;
    padding: 1.3rem 0;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1rem;
}
/* width */
.modalBody::-webkit-scrollbar {
    width: 10px;
}
/* Track */
.modalBody::-webkit-scrollbar-track {
    background: #f1f1f1; 
}
/* Handle */
.modalBody::-webkit-scrollbar-thumb {
    background: #888; 
}
/* Handle on hover */
.modalBody::-webkit-scrollbar-thumb:hover {
    background: #555; 
}
.modalBody .btn.principal{
    width: 100%;
}
@media (max-width: 600px) {
    .baseModal {
        align-items:unset;
        padding-top: 10%;
    }
    .modalBody{
        position: relative;
        max-width: 480px;
        max-height: 290px;
        padding: 40px;
        background-color: #0B0D1A;
        border:1px solid rgb(182, 182, 182);
        box-sizing: content-box;
        margin: 0 15px;        
        overflow: auto;
    }
    .baseModal .modalLogin .modalLogin_cerrar{
        top:10px;
        right: 30px;
    }
    .modalBody .tituloheader{
        font-size: 25px;
        margin-bottom: 10px;
    }
    .modalBody .titulo{
        font-size: 22px;
        line-height: 1.5rem;
    }
    .modalBody .labelInput{
        font-size: 14px;
    }
  }
  @media (max-width: 400px) {
    .baseModal .modalLogin{
        position: fixed;
        top:0;      
        margin: 0;
        width: calc(100vw-30px);
        max-width: 100%;
        max-height: fit-content;
        height: 100vh;
        border: none;
        padding: 3rem 2rem;
    }
  }