.homeeventos_content{
    margin:70px;
}
.homeeventos_content h2{
    text-align: center;
    margin-bottom: 70px;
    font-size: 36px;
    color: white;
}
.homeeventos_content h2 span{
    color: #00E1FA;
}
.grid_homeeventos{
    display: grid;
    grid-template-columns: 378px 378px 378px 378px;
    gap: 22px;
    justify-content: center;
}
.cajaItem_eventoPresencial{
    height: 378px;
    width: 378px;
    cursor: pointer;
}
.cajaItem_eventoPresencial img{
    width: 378px;
    object-fit: cover;
}

@media (max-width: 1680px) {
    .grid_homeeventos{
        grid-template-columns: 378px 378px 378px;
    }
  }
@media (max-width: 1300px) {
    .grid_homeeventos{
        grid-template-columns: 378px 378px;
    }
  }
@media (max-width: 850px) {
    .homeeventos_content h2{
        font-size: 30px;
    }
    .grid_homeeventos{
        grid-template-columns: auto;
    }
    .cajaItem_eventoPresencial{
        height: 100%;
        width: 100%;
    }
    .cajaItem_eventoPresencial img{
        width: 100%;
        object-fit: cover;
    }
  }
  @media (max-width: 500px) {
    .homeeventos_content h2{
        font-size: 26px;
        margin-bottom: 50px;
    }
    .homeeventos_content{
        margin:50px 30px;
    }
  }

.container-carrusel .cajaItem_eventoPresencial{
    position: relative;
    background: #000000;
    border-radius: 5px;
    box-shadow: 0px 0px 20px #00000065;
    text-align: left;
    overflow: hidden;
    width: 360px;
    height: 390px;
    margin-right: 1.5rem;
}

