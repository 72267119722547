.caja-item-registro-ponente-nombre{
    color: #DDDDDD;
    cursor: pointer;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif ;
    font-weight: 600;
}
.caja-item-registro-ponente-cargo{
    color: #999999;
    cursor: pointer;
    font-size: 14px;
    font-family: 'Montserrat', sans-serif ;
    font-weight: 600;
}
.caja-item-registro-ponente-empresa{
    color: #999999;
    cursor: pointer;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif ;
    font-weight: 600;
}

.caja-item-registro-ponente-contendor-imagen{
    background-color: #000;
    flex-wrap: wrap;
    overflow: hidden;
    position: relative;
    display: flex;
}


.caja-item-registro-ponente-contendor-imagen .caja-item-registro-etiqueta {
    opacity: .9;
    position: absolute;
    margin: 10px;
}

.caja-item-registro-ponente-body {
    margin-bottom: 10px;
    padding: 0 20px;
}

.caja-item-registro-ponente-contendor-imagen .caja-item-registro-etiqueta{
    color:#000000B3;
}

.caja-item-registro-ponente-etiquetas {
    display: flex;
    margin: 10px 0;
    flex-wrap: wrap;
}

.caja-item-registro-ponente-etiqueta{
    background: #CCCCCC;
    color: #000000;
    font-size: 12px;
    margin-right: 10px;
    padding: 2px 3px;
    border-radius: 3px;
    margin-bottom: 10px;
}

.caja-item-registro-ponente-etiqueta.dark{
    background: #333333;
    color: #999999;
}

.caja-item-registro-ponente-footer {
    padding: 10px 20px 10px 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.caja-item-registro-ponente-like{
    cursor: pointer;
}

.caja-item-registro-ponente-fecha{
    color: #DDDDDD;
    font-size: 12px;
    font-family: 'Montserrat', sans-serif ;
} 
.caja-item-registro-ponente .disponible{
    color: #DDDDDD; 
}
.caja-lateral .btn_quieroinscribirme{
    margin-top: 1rem;
}
.btn_quieroinscribirme{
    background-color: #54A6F8;
    width: 100%;
    font-size: 1.2rem;
    margin-bottom: 0.9rem;
    font-weight: 400;
    letter-spacing: 0.36px;
    border-radius: 5px;
    display: inline-block;
    color: #fff;
    line-height: 27px;
    padding: 17px 30px;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
}
.btn_inscribirse{
    width: 100%;
    font-size: 1rem;
    margin-bottom: 0.5rem;
    font-weight: 600;
}
.btn_inscribirse a{
    color: white;
}
.btn_inscribirse.btn_narrow{
    max-width: 315px;
    margin-bottom: 70px;
}
.registered_buttons{
    display: flex;
    flex-direction: column;
}
.registered_buttons div{
    padding: 1rem;
    text-transform: uppercase;
    background-color: #3C3C3C;
    color: #7A7A7A;
    text-align: center;
    border-radius: 5px;
    margin-bottom: 1rem;
    font-weight: 600;
}
.registered_buttons button{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.8rem;
    border: 1px solid white;
    background-color: #262632;
    color: white;
    text-align: center;
    border-radius: 5px;
    margin-bottom: 1rem;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
}
.registered_buttons button span{
    font-family: 'Material Icons';
    font-size: 1.8rem;
    margin-right: 1rem;
}
.form-aviso_span{
    text-decoration: underline;
    color: white;
    cursor: pointer;
}
.form-aviso_span_acepto{
    color: #00E1FA;
}
.detallesorden2_separador{
    margin:25px 0;
    border-top:1px dashed #999999;
}

