.grid-container-ponentes {
  display: flex;
  padding-bottom: 100px;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.grid-container-ponentes  .grid-item{
  text-align: center;
  max-width: 275px;
  margin-right: 1rem;
  background-color: #2F2F40;
  border-radius: 10px;
}
hr.separdor-puntos {
  border-top: dashed 10px;
  border-style: none none dashed;
  background-color: transparent;
  margin: 20px 0;
}
.container_ponentes .titulo{
  font-size: 38px;
  margin-bottom: 29px;
  font-weight: 600;
}
@media (max-width: 850px) {
  .grid-container-ponentes{
    justify-content: center;
  }
}
@media (max-width: 500px) {
  .container_ponentes .titulo{
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
}

  