.contenido_datosusuario{
    display: grid;
    grid-template-columns: 60% auto;
    margin:0 2rem;
    margin-bottom: 3rem;
}
.contenido_datosusuario2{
    display: grid;
    grid-template-columns: 50%;
    margin:0 2rem;
    margin-bottom: 3rem;
}
.contenido_datosusuario2 .contenido_datosusuario_form{
    margin-right: 0;
}
.contenido_datosusuario_form {
    margin-top: 30px;
    margin-right: 100px;   
}
.contenido_datosusuario .titulo {
    font-weight: 500;
    font-size: 28px;
    margin-bottom: 40px;
}
.contenido_datosusuario2 .titulo {
    font-size: 28px;
    margin-bottom: 40px;
}
.contenido_datosusuario_form .subtitulo{
    color: white;
    font-size: 18px;
    margin-bottom: 20px;
    font-weight: 400;
}
.contenido_datosusuario_form .titulo.titulo_intereses{
    margin-bottom: 20px;
}
.caja-item-cuenta{
    margin-top: 30px;
    text-align: center;
}

img.imagen-cuenta {
    width: 250px;
    border-radius: 50%;
}

.btn-cambiar-imagen {
    margin-top: 10px;
    text-align: center;
    cursor: pointer;
    color: #fff;
}

.btn.formulario {
    margin-top: 30px;
    width: 100%;
}
@media (max-width: 1300px) {
    .contenido_datosusuario_form{
        margin-right: 50px;  
    }
}
@media (max-width: 1200px) {
    .contenido_datosusuario{
        margin:0 1rem;
    }
    .contenido_datosusuario2{
        margin:0 1rem;
    }
}
@media (max-width: 990px) {
    .contenido_datosusuario{
        grid-template-columns: auto;
    }
    .contenido_datosusuario2{
        grid-template-columns: auto;
    }
    .contenido_datosusuario_form{
        margin-bottom: 2rem;
        margin-right: 0px;   
    }
}
@media (max-width: 500px) {
    .btn.formulario {
        font-size: 1rem;
    }
    .cabecera-cuenta-usuario .titulo, .cabecera-cuenta-usuario .titulo span{
        font-size: 35px;
        margin-bottom: 10px;
        line-height: 40px;
    }
    .cabecera-cuenta-usuario .subtitulo{
        font-size: 20px;
    }
}