.modalsettings{
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: rgba(11, 13, 26, .80);
    z-index: 3;
}
.modalsettings_body{
    position: relative;
    width: 480px;
    padding: 50px;
    background-color: #0B0D1A;
    border:1px solid #707070;
    box-sizing: content-box;
    border-radius: 5px;
}
.modalsettings_body .modalsettings_cerrar{
    position: absolute;
    top:-28px;
    right: 0;
    font-size: 0.65rem;
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;
}
.modalsettings_body_logo{
    color: white;
    text-align: center;
    margin-bottom: 3rem;
}
.modalsettings_body_logo span{
    color:#00E2FA;
    margin-left: 0.15rem;
}
.modalsettings_form h2{
    color: white;
    font-size: 1.5rem;
    margin-bottom: 2rem;
    font-weight: 500;
}
.modalsettings_form_item{
    border: 1px solid #7a7a7a;
    border-radius: 5px;
    padding: 0.8rem;
    margin-bottom: 1.5rem;
}
.modalsettings_form_item h3{
    font-size: 1rem;
    color: white;
    margin-bottom: 0.2rem;
    font-weight: 400;
}
.modalsettings_form_item p{
    color: #999999;
    font-weight: 400;
    font-size: 0.8rem;
    margin-top: 5px;
    margin-bottom: 15px;
}
.modalsettings_form_item label{
    color: #999999;
    font-weight: 400;
    font-size: 0.8rem;
    margin-left: 0.25rem;
}
.modalsettings_form button{
    padding: 1.2rem;
    width: 100%;
    border:1px solid #999999;
    border-radius: 5px;
    background-color: #0B0D1A;
    color: #999999;
    text-transform: uppercase;
    font-size: 0.95rem;
    font-weight: 400;
    letter-spacing: 1px;
    text-align: center;
    cursor: pointer;
}