.baseModal {
    width: 100%;
    height: 100vh;
    top:0;
    left:0;
    background-color: rgba(11, 13, 26, .80);
    position: fixed;
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.baseModal.open{
    display: flex;
}

.modalBody{
    position: relative;
    max-width: 480px;
    max-height: 70vh;
    padding: 50px;
    background-color: #0B0D1A;
    border:1px solid #707070 ;
    border-radius: 5px;
    box-sizing: content-box;
    margin: 0 15px;
}
.modalBody .modalLoginInscripcion_cerrar{
    position: absolute;
    top:-28px;
    right: 0;
    font-size: 0.65rem;
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;
}

.modalBody .modalX .titulo{
    font-size: 26px;
    line-height: 32px;
    margin-bottom: 20px;
}

.modalBody .tituloheader{
    text-align: center;
    font-size: 36px;
    line-height: 42px;
    margin-bottom: 35px;
}
.modalBody.modalLoginInscripcion .form-group{
    margin-bottom: 15px;
}

.helperLogin{
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.helperLogin .small{
    cursor: pointer;
}


.small{
    font-size: 12px;
}

label.small{
    font-size: 14px;
}

.inputText{
    height: 60px;
    background: #707070;
    border-radius: 5px;
    border: none;
    margin: 10px 0;
    color: #fff;
    font-size: 16px;
    padding: 0 15px;
    width: 100%;
}

.labelInput{
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
    color: #FFF;
}
.boton_iniciarsesion{
    background-color: #bbf1a0;
    width: 100%;
    border-radius: 5px;
    border: none;
    padding: 1.3rem 0;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1rem;
}
/* width */
.modalBody::-webkit-scrollbar {
    width: 10px;
}
/* Track */
.modalBody::-webkit-scrollbar-track {
    background: #f1f1f1; 
}
/* Handle */
.modalBody::-webkit-scrollbar-thumb {
    background: #888; 
}
/* Handle on hover */
.modalBody::-webkit-scrollbar-thumb:hover {
    background: #555; 
}
.modalBody .btn.principal{
    width: 100%;
}
.modalXbody{
    padding: 50px 70px;
}
.modalXbody img{
    width: 100%;
    height: 130px;
    object-fit: contain;
}
.modalXbody .btn{
    margin-top: 1rem;
}
.modalX p{
    color: white;
    margin-bottom: 20px;
    line-height: 22px;
}
.modalX span{
    margin-bottom: 40px;
    font-size: 16px;
    line-height: 22px;
}
.modalX .btn.transparente{
    width: 100%;
    padding: 15px;
}
.modalXbody_logo{
    width: 200px;
    margin: 0 auto;
}
@media (max-width: 600px) {
    .baseModal {
        align-items:unset;
        padding-top: 10%;
    }
    .modalBody{
        position: relative;
        max-width: 480px;
        max-height: 290px;
        padding: 40px;
        background-color: #0B0D1A;
        border:1px solid rgb(182, 182, 182);
        box-sizing: content-box;
        margin: 0 15px;        
        overflow: auto;
    }
    .modalBody .modalLoginInscripcion_cerrar{
        top:10px;
        right: 10px;
    }
    .modalBody .tituloheader{
        font-size: 25px;
        margin-bottom: 10px;
    }
    .modalBody .titulo{
        font-size: 22px;
        line-height: 1.5rem;
    }
    .modalBody .labelInput{
        font-size: 14px;
    }
  }
  @media (max-width: 500px) {
    .modalXbody .btn{
        font-size: 0.9rem;
    }
  }
  @media (max-width: 400px) {
    .baseModal .modalXbody{
        position: relative;
        top: 0;
        margin: 0;
        max-width: 100%;
        max-height: -moz-fit-content;
        max-height: fit-content;
        height: 100vh;
        border: none;
        padding: 10px;
        margin-top: 0;
    }
    .modalBody .modalLoginInscripcion_cerrar{
        top: 42px;
        right: 30px;
    }
  }

.modalTerminosUsuarioRegistrad .modalXbody_logo {
    width: 200px;
    margin: 0 auto;
    margin-top: 60px;
}

.modalHeader{
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    display: flex;
    margin-bottom: 20px;
    border-bottom: #707070 solid 1px;
    justify-content: flex-start;
    align-items: center;
}

.modalHeader .fecha{
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.modalHeader .fecha .mes{
    font-size: 13px;
}
.modalHeader .fecha .dia{
    font-size: 28px; 
    color: #FFF;
}


.modalHeader .fecha .anyo{
    font-size: 13px;
}
.modalHeader .info{
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-left: #707070 solid 1px;
    align-items: flex-start;
    padding: 10px 20px;
}

.modalHeader .info.no-fecha {
    border-left: none;
    padding: 25px 30px 15px 30px;
}

.modalHeader .info .titulo{
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 0px;
    color: #FFF;
}

.modalHeader .info .subtitulo{
    font-size: 12px;
    margin-bottom: 0px;
}