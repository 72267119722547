.contenido-cuenta {
    margin-top: 30px;
    padding-left: 50px;
    padding-right: 50px;
    margin-bottom: 3rem;
}

.contenido-cuenta .titulo {
    font-size: 28px;
    margin-bottom: 40px;
}

.caja-item-cuenta{
    margin-top: 30px;
    text-align: center;
}

img.imagen-cuenta {
    border-radius: 50%;
}

.btn-cambiar-imagen {
    margin-top: 10px;
    text-align: center;
    cursor: pointer;
    color: #fff;
}

.btn.formulario {
    margin-top: 30px;
    width: 100%;
}
.content-labels .intereses_label{
    font-size: 14px;
    padding: 4px 12px;
    font-weight: 400;
}