.form-group {
    position: relative;
}

.inputText {
    position: relative;
    height: 60px;
    background: #707070;
    border-radius: 5px;
    border: none;
    width: 100%;
    margin: 10px 0;
    color: #fff;
    font-size: 16px;
    padding: 0 50px 0 15px;
}

.icono-input {
  float: right;
  margin-left: -30px;
  margin-top: 30px;
  position: absolute;
  z-index: 2;
  cursor: pointer;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.cuenta_settings{
  padding-right: 50px;
  margin-top: 1rem;
}
.cuenta_settings_item{
  margin: 30px 0;
}
.cuenta_settings_item h3{
  color: white;
  font-size: 1.2rem;
  margin-bottom: 0.3rem;
}
.cuenta_settings_item_selector{
  display: flex;
}
.cuenta_settings_item_selector p{
  font-size: 15px;
  font-weight: 400;
  padding: 1rem;
  border: 1px solid #7a7a7a;
  border-radius: 5px;
  margin-right: 0.5rem;
}
.cuenta_settings_item_selector_icon{
  font-family: 'Material Icons';
  font-size: 1.6rem;
  color: #7a7a7a;
  margin-top:0.8rem;
  cursor: pointer;
}
.cuenta_settings_item_selector_icon span{
  border: 1px solid #7a7a7a;
  border-radius: 5px;
  padding: 0.7rem;
}
.cuenta_settings_item_selector_icon span:hover{
  border: 1px solid white;
  color: white;
}
.contenido_datosusuario_form .titulo_seguridad{
  font-weight: 500;
}
.cuenta_settings_item h3{
  font-weight: 500;
  font-size: 28px;
  margin-bottom: 25px;
}
@media (max-width: 1400px) {
  .cuenta_settings{
    padding-right: 0rem;
  }
}
@media (max-width: 990px) {
  .cuenta_settings{
    padding: 0;
    padding-right: 6rem;
  }
}
@media (max-width: 500px) {
  .cuenta_settings{
    padding: 0;
    padding-right: 4rem;
  }
}
