.sectionFooter{
    border-top: #7A7A7A solid 1px;
    background: #000;
    padding: 15px 0;
    font-family: "roboto";
}

.logo-footer {
    text-align: center;
    margin: 20px 0;
}

.logo-footer img, .logo-footer svg{
    max-width: 120px;
}

.footer{
    padding-bottom: 30px;
    background: #000000;
}

.footer .titulo{
    text-align: center;
    font-size: 20px;
}

.menuFooter{
    display: flex;
    justify-content: center;
    margin: 10px 0 20px 0;
    flex-wrap: wrap;
}
.menuFooter li{
    margin-top: -0.5rem;
    list-style-type: none;
}
.menu_footer_logo{
    text-align: center;
    margin: 20px 0 35px 0;
}
.menu_footer_logo img, .menu_footer_logo svg{
    width: 8.5rem;
}

.itemMenuFooter{
    font-size: 20px;
    font-weight: 400;
    margin: 0 5px;
    cursor:pointer;
    letter-spacing: 1px;
    color: #7A7A7A;
}

.itemMenuFooter:after {
    content: "·";
    margin-left: 10px;
}

.copyfooter{
    text-align: center;
    color: #7a7a7a;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 1px;

}
.copyfooter a{
    color: #7a7a7a;
    font-weight: 400;
    letter-spacing: 1px;
}
.copyfooter a:hover{
    color: white;
}
.copyfooter img{
    width: 150px;
    margin-top: 15px;
}
.menuFooter_rrss{
    display: flex;
    margin-left: 5px;
}
.menuFooter_rrss a{
    margin-right: 10px;
    margin-top: -6px;
    fill: #7A7A7A;
}
.menuFooter_rrss a:hover{
    fill: white;
}
.menuFooter_rrss img{
    vertical-align:middle;
}
@media (max-width: 1437px) {
    .menuFooter_rrss a{
        margin-top: 2px;
    }
}
@media (max-width: 1450px) {
    .menuFooter_rrss a{
        margin-top: -6px;
    }
}
@media (max-width: 1223px) {
    .menuFooter_rrss a{
        margin-top: -8px;
    }
}
@media (max-width: 1004px) {
    .menuFooter_rrss a{
        margin-top: -1px;
    }
}
@media (max-width: 1210px) {
    .itemMenuFooter{
        font-size: 1rem;
    }
    .copyfooter{
        font-size: 0.8rem;
    }
}
@media (max-width: 1000px) {
    .itemMenuFooter{
        line-height: 2rem;
    }
}
@media (max-width: 893px) {
    .menuFooter_rrss a{
        margin-top: 4px;
    }
}
@media (max-width: 880px) {
    .menuFooter{
        flex-direction: column;
        align-items: center;
    }
    .itemMenuFooter{
        margin-bottom: 0.3rem;
    }
    .itemMenuFooter:after {
        content: "";
        margin-left: 5px;
    }
}
@media (max-width: 600px) {
    
    .copyfooter{
        padding:0 20px;
    }
}

