.menu_header_contenedor{
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 100;
    font-family: 'Roboto', sans-serif;
}
.menu_header ul{
    list-style-type: none;
}
.menu_header{
    display: flex;
    background-color: black;
    align-items: center;
    justify-content: space-between;
    padding: 0;
}
.menu_header_logo{
    display: flex;
    align-items: center;
    margin-left: 8%;
    margin-right: 3rem;
}
.menu_header_logo img, .menu_header_logo svg{
    width: 100px;
}
.menu_header_items{
    display: flex;
    margin: 0;
    position: relative;
    min-width: 65%;
    height: 90px;
    justify-content: flex-start;
    
}
.menu_header_items li{
    margin-top: 1.5rem;
    margin-bottom: 1.2rem;
    padding-bottom: 4px;
    margin-right: 3rem;
    text-transform: uppercase;
    font-size: 20px;
}
.menu_header_items li a{
    color: #7A7A7A;
    letter-spacing: 0.04rem;
    font-weight: 400;
}
.menu_header_items .menu_active{
    color: white;
    padding-bottom: 3px;
}
.menu_header_items li a:hover{
    color: white;
    padding-bottom: 0px;
    border-bottom:2px solid white;
    line-height: 22px;
}
.menu_header_items ul{
    display: none;
    position:absolute;
    background-color: black;
}
.menu_header_items li:hover > ul {
    display:block;
}
.menu_header_items li ul{
    margin-top: 0.2rem;
    margin-left: -1.5rem;
}
.menu_header_items li ul li {
    position:relative;
    margin: 0.2rem 3rem;
    margin-left: 1rem;
    padding-top: 1rem;
    border-top: 1px solid silver;
}
.menu_header_items li ul li:hover{
    padding-bottom: 3px;
}
.menu_header_users{
    display: flex;
    margin-right: 8%;
}
.menu_header_auth_users{
    display: flex;
    align-items: center;
    margin-right: 2rem;
    cursor: pointer;
}
.menu_header_auth_users img{
    width: 48px;
    height: 48px;
    border-radius: 50%;
    object-fit: cover;
}
.menu_header_auth_users a{
    color:white;
    font-size: 20px;
    margin-right: 15px; 
    user-select: none;
    font-weight: 400;
}
.menu_header_auth_users a:hover{
    border-bottom:2px solid white;
    padding-top: 6px;
    padding-bottom: 3px;
}
.menu_header_auth_desplegable{
    position: absolute;
    background-color: #000000;
    padding: 30px;
    border:1px solid #323233;
    text-align: right;
    font-weight: 400;
    right: 5rem;
}
.menu_header_auth_desplegable li{
    margin-bottom: 20px;
    cursor: pointer;
}
.menu_header_auth_desplegable li:last-of-type{
    margin-bottom: 0;
}
.menu_header_users button{
    font-family:  'Montserrat', sans-serif ;
    background: rgb(80,164,251);
    border-radius: 5px;
    border: none;
    color: #fff;
    font-weight: 500;
    font-size: 15px;
    letter-spacing:0.05rem;
    padding:0 16px;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    margin-right: 1.5rem;
    height: 40px;
}
.menu_header_users button:first-of-type {
    background: rgb(80,164,251);
    background: linear-gradient(90deg, rgba(80,164,251,1) 0%, rgba(0,226,250,1) 100%);
    border: 1px solid #00E2FA;
}

.menu_header_users button:nth-of-type(2) {
    background: #000;
    border: 1px solid #FFFFFF;
    margin-right: 0;
}
.menu_header_burger{
    width: 100%;
    position: relative;
    display: none;
    justify-content: space-between;
    background-color: black;
    align-items: center;
    padding: 0;
    z-index: 9999;
    padding: 1rem 0;
}
.menu_header_burger_burger{
    color: white;
    font-size: 1.2rem;
    transform: rotate(90deg);
    border: 1px solid white;
    padding: 0.25rem 1rem 0.45rem 1rem;
    margin-right: 2rem;
    cursor: pointer;
}
.menu_header_burger_logo{
    margin-left: 2rem;
}
.menu_header_burger_logo img, .menu_header_burger_logo svg{
    width: 100px;
}
.b_menu_header{
    display: none;
}
.b_menu_header ul{
    list-style-type: none;
}
.b_menu_header_auth{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    text-align: center;
}
.b_menu_header_auth li{
    margin-top: 1.5rem;
    margin-bottom: 1.2rem;
    text-transform: uppercase;
    font-weight: 400;
}
.b_menu_header_auth_users{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    font-size: 24px;
    padding-bottom: 10px;
    border-bottom:2px solid white;
    font-weight: 400;
}
.b_menu_header_auth_users a{
    user-select: none;
    color: white;
}
.b_menu_header_auth ul li a{
    cursor: pointer;
    padding-bottom: 3px;
}
.b_menu_header_auth ul li a:hover{
    border-bottom: 2px solid white;
    color: white;
}
.b_menu_header_auth_users img{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    margin-left: 10px;
}
.menu_separador{
    width: 100%;
    height: 1px;
    background-color: white;
}

@media (max-width: 1400px) { 
    .menu_header_logo{
        margin-left: 3%;
    }
    .menu_header_users{
        margin-right: 3%;
    }
}
@media (max-width: 1250px) { 
    .menu_header_items li{
        margin-right: 1.5rem;
    }
}
@media (max-width: 1200px) {
    .menu_header{
        display: none;
    }
    .menu_header_burger{
        display: flex;
        z-index:9999;
    }
    .b_menu_header{
        display: flex;
        background-color: black;
        align-items: center;
        padding: 0;
        order: 2;
        position:fixed;
        width: 100%;
        padding-top:3rem;
        flex-direction: column;
        justify-content: start;
        height: 100vh;
    }
    .b_menu_header_items{
        display: flex;
        position:unset;
        flex-direction: column;
        align-items: center;
        margin: 3rem 0 1.5rem 0;
        padding: 0;
    }
    .b_menu_header_items li{
        margin-top: 1.5rem;
        margin-bottom: 1.2rem;
        padding-bottom: 4px;
        text-transform: uppercase;
        font-size: 0.9rem;
        font-size: 1.5rem;
        font-weight: 400;
    }
    .b_menu_header_items .menu_active{
        color: white;
    }
    .b_menu_header_items li a:hover{
        color: white;
        border-bottom: 2px solid white;
        padding-bottom: 3px;
    }
    .b_menu_header_items ul{
        display: flex;
        flex-direction: column;
        align-items: center;
        position:unset;
        background-color: black;
    }
    .b_menu_header_users{
        display: flex;
        flex-direction: column;
        margin-right: 0;
        margin-bottom:3rem;
    }
    .b_menu_header_users button{
        background: rgb(80,164,251);
        border-radius: 5px;
        border: none;
        color: #fff;
        font-weight: 500;
        letter-spacing:0.05rem;
        text-align: center;
        text-transform: uppercase;
        cursor: pointer;
        font-size: 1rem;
        padding: 1rem 2rem;
        margin-right: 0;
        margin-bottom: 1rem;
    }
    .b_menu_header_users button:first-of-type {
        background: rgb(80,164,251);
        background: linear-gradient(90deg, rgba(80,164,251,1) 0%, rgba(0,226,250,1) 100%);
        border: 1px solid #00E2FA;
    }
    .b_menu_header_users button:nth-of-type(2) {
        background: #000;
        border: 1px solid #FFFFFF;
    }
    .b_menu_header_burger{
        display: flex;
    }
}

