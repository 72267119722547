.misInscripciones_content{
    margin-bottom: 80px;
}
.misInscripciones_content:last-of-type{
    margin-bottom: 0;
}
.misInscripciones_header{
    display: flex;
    justify-content: space-between;
    margin-bottom: 60px;
    align-items: center;
}
.misInscripciones_header h1{
    font-size: 28px;
    font-weight: 500;
    color: white;
    margin-right: 5rem;
}
.misInscripciones_buscador{
    display: flex;
    flex-direction: column;
    position: relative;
}
.buscador_default{
    display: flex;
    align-items: center;
    cursor: pointer;
    fill: #999999;
    user-select: none;
}
.buscador_deplegable{
    display: flex;
    flex-direction: column;
    position: absolute;
    top:2.1rem;
    right: 0;
    width: 247px;
    padding: 15px 0;
    background-color: #282834;
    border: 1px solid #666666;
    font-size: 16px;
    font-weight: 500;
}
.misInscripciones_buscador .buscador_deplegable p{
    width: 100%;
    margin: 0;
    padding: 5px 15px;
    cursor: pointer;
    user-select: none;
}
.misInscripciones_buscador .buscador_deplegable p:hover{
    color: white;
    background-color: #707070;
 }
.selected{
    color: white;
}
.selected::after{
    content: "✓";
    font-size: 14px;
    margin-left: 10px;
    color: #BBF1A0;
}
.buscador_default:hover{
    color: white;
    fill: white;
}
.misInscripciones_buscador p{
    font-size: 16px;
    margin:0 0 0 5px;
}
.misInscripciones table{
    width: 100%;
}
.misInscripciones td,.misInscripciones th{
    text-align: start;
}
.misInscripciones_content .misInscripciones_head{
    border-bottom: 1px solid #00E1FA;
    color: white;
    font-size: 16px;
    font-weight: 500;
}
.misInscripciones_head th{
    padding: 0 15px;
    padding-bottom: 10px;
}
.misInscripciones_content tr:nth-child(odd){
    background-color: #35353f;
}
.misInscripciones_content tr:first-of-type{
    background-color: rgba(0, 0, 0, 0);
}
.misInscripciones_content tr:nth-of-type(2) td{
    padding-top: 30px;
}
.misInscripciones_content td{
    font-size: 15px;
    padding: 20px 15px;
    line-height: 1.8rem;
    max-width: 300px;
}
.misInscripciones_content .misInscripciones_fecha{
    color:#00E1FA;
}
.misInscripciones_content .misInscripciones_fecha span{
    color:#999999;
}
.misInscripciones_content .fechaPasada{
    color:#999999;
}
.misInscripciones_content .misInscripciones_fecha span::before {
     content: '\A';
     white-space: pre;
    }
.misInscripciones_content .misInscripciones_tipo span::before {
     content: '\A';
      white-space: pre;
    }
.misInscripciones_content tr td a{
    background-color: #54A6F8;
    font-size: 12px;
    text-transform: uppercase;
    color: white;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    white-space: nowrap;
}
.cargarMasEventos{
    display: flex;
    justify-content: center;
    margin-top: 30px;
}
.cargarMasEventos button{
    background: black;
    border: 1px solid #FFFFFF;
    font-size: 12px;
    text-transform: uppercase;
    color: white;
    padding: 10px 25px;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    white-space: nowrap;
}


@media (max-width: 1200px) {
    .misInscripciones{
        margin-top: 50px;
    }
}
@media (max-width: 790px) {
    .misInscripciones{
        overflow-x: scroll;
    }
    /* width */
    .misInscripciones::-webkit-scrollbar {
        width: 10px;
    }
    
    /* Track */
    .misInscripciones::-webkit-scrollbar-track {
        background: #f1f1f1; 
    }
    
    /* Handle */
    .misInscripciones::-webkit-scrollbar-thumb {
        background: #888; 
    }
    
    /* Handle on hover */
    .misInscripciones::-webkit-scrollbar-thumb:hover {
        background: #555; 
    }
}