.bannerPrincipal {
  position: relative;
}
.bannerPrincipal_background img{
  height: 504px; /*570px;*/
  object-fit: cover;
  vertical-align: bottom;
  object-position: left;
}
.banner-interior.bannerPrincipal .bannerPrincipal_background img{
  height: 504px;
  object-fit: cover;
  vertical-align: bottom;
  object-position: left;
}
.bannerInterior .bannerPrincipal_background img{
  height: 504px;
  object-fit: cover;
  vertical-align: bottom;
  object-position: left;
}
.bannerPrincipal_content {
  position: absolute;
  top: 100px;
  left: 150px;
  width: 700px;
}
.banner-interior.bannerPrincipal.bannerPrincipal_content {
  top: 50px;
}

.bannerInterior .bannerPrincipal_content {
  top: 50px;
}

.bannerPrincipal_content img{
  width: 325px;
  margin-bottom: 50px;
}
.bannerPrincipal_content .titulo{
  color: #FFFFFF;
  font-size: 38px;
  font-weight: 600;
  line-height: 49px;
  margin-bottom: 50px;
}
.bannerPrincipal_content .subtitulo{
    color: #999999;
    font-size: 19px;
    line-height: 29px;
    font-weight: 600;
    margin-bottom: 50px;
}
.bannerPrincipal_btn{
  width: 446px;
  background: linear-gradient(90deg, rgba(80,164,251,1) 0%, rgba(0,226,250,1) 100%);
  border: 1px solid #00E2FA;
  border-radius: 5px;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  line-height: 27px;
  padding: 10px 50px;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
}
@media (max-width: 1200px) {
  .bannerPrincipal_background img{
    object-position: 80%;
  }
}
@media (max-width: 800px) {
  .bannerPrincipal_content {
    top: 10%;
    left: 10%;
    width: 80%;
    background: rgb(0,4,9);
    background: linear-gradient(0deg, rgba(0,4,9,1) 0%, rgba(0,7,9,0.8491771708683473) 65%, rgba(0,8,9,0.6671043417366946) 87%, rgba(0,8,9,0) 100%);
    z-index: 99;
    position: relative;
    left: 0;
    width: 100%;
    padding: 5%;
    margin-top: -400px;
    padding-bottom: 40px;
  }
  .slider-principal .slick-dots {
    bottom: 0px!important;
    width: 100%;
    margin: 0;
    list-style: none;
    text-align: center;
  }
  .banner2 .titulo {
    font-size: 30px;
    font-weight: 500;
    line-height: 32px;
    margin-bottom: 10px;
  }
}
@media (max-width: 600px) {
  .bannerPrincipal_background img{
    object-position: 70%;
  }
  .banner2 .item-etiqueta{
    margin-bottom: 5px;
  }
  .bannerPrincipal_content {
    top: 10%;
    left: 10%;
    width: 80%;
    background: rgb(0,4,9);
    background: linear-gradient(0deg, rgba(0,4,9,1) 0%, rgba(0,7,9,0.8491771708683473) 65%, rgba(0,8,9,0.6671043417366946) 87%, rgba(0,8,9,0) 100%);
    z-index: 99;
    position: relative;
    left: 0;
    width: 100%;
    padding: 5%;
    margin-top: -400px;
    padding-bottom: 40px;
  }
  .bannerPrincipal_content img{
    width: 200px;
    margin-bottom: 25px;
  }
  .bannerPrincipal_content .titulo{
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 25px;
  }
  .bannerPrincipal_content .subtitulo{
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 25px;
  }
  .bannerPrincipal_btn{
    width: 90%;
    font-size: 12px;
    line-height: 15px;
    padding: 10px 20px;
  }
}


.banner2{
  height:504px
}
.banner2 .item-etiqueta{
  background: #CCCCCC;
  color: #000000;
  font-size: 13px;
  margin-right: 5px;
  padding: 3px 4px;
  border-radius: 3px;
}
.banner2 .etiqueta_tipo{
  background:#262632;
  color: #999999;
}
.banner2 .etiqueta_categoria{
  background: #000000;
  color: #CCCCCC;
}
.banner2 .titulo{
  font-size: 38px;
  font-weight: 500;
  margin-bottom: 10px;
}
.banner2 .destacado{
  margin-bottom: 20px;
}
.banner2_btn{
  background: #000;
  border: 1px solid #FFFFFF;
  border-radius: 5px;
  display: inline-block;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  line-height: 27px;
  padding: 10px 50px;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
}
.bannerPrincipal .homepublica_repuesto_btn{
  position: absolute;
  bottom:60px;
  left: 150px;
  z-index: 10;
}
@media (max-width: 800px) {
  .bannerPrincipal .homepublica_repuesto_btn {
    left: 10%;
  }
  .banner2{
    height: auto!important;
    top: 0!important;
    padding-top: 200px;
    margin-top: -510px;
  }
  .slider-principal button.slick-arrow {
    display: none!important;
  }
  
  .bannerInterior .bannerPrincipal_background img {
    height: 504px;
    object-fit: cover;
    vertical-align: unset;
    object-position: 70%;
    /* ajuste para banners sin capa texto y texto en imagen
    height: auto;
    object-fit: cover;
    vertical-align: unset;
    object-position: left;
    width: 100%;*/
  }
  /* ajuste para banners sin capa texto y texto en imagen
  .bannerInterior .bannerPrincipal_content.banner2 {
    height: auto!important;
    top: 0!important;
    padding-top: 30px;
    margin-top: 0px;
  }
   ajuste para banners sin capa texto y texto en imagen
  .bannerInterior  .bannerPrincipal_background {
    display: flex;
  }*/
}
@media (max-width: 600px) {
  .bannerPrincipal .homepublica_repuesto_btn {
    width: 350px;
    bottom:120px;
  }
  .bannerPrincipal button.slick-arrow {
    display: none!important;
  }
}
@media (max-width: 425px) {
  .bannerPrincipal .homepublica_repuesto_btn {
    width: 250px;
    bottom:30px;
  }
  .cookiemodal_buttons{
    margin-bottom: 10px;
  }
}

.slick-slider.slider-principal {

  background: #000;
  display: none!important;
}

.slick-slider.slider-principal.slick-initialized{
  display: inherit!important;
}
