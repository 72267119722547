
.caja-texto {
    margin: 20px;
}

.caja-texto .titulo {
    margin-bottom: 50px;
}

.caja-texto .lista ul {
    margin: 20px 0;
    padding: 0;
    list-style: none;
    font-weight: 400;
    font-size: 19px;
    line-height: 1.5rem;
}

.caja-texto .lista ul li:before {
    content: "·";
    margin-right: 10px;
}
.homepublica_haztemiembro2{
    max-width: 90%;
}
.homepublica_haztemiembro .titulo{
    font-weight: 500;
    line-height: 49px;
}

@media (max-width: 768px) { 
    .homepublica_haztemiembro .titulo{
      font-size: 24px;
      line-height: 32px;
  
    }
  }


.homepublica_haztemiembro_btn{
    width: 75%;
    background: linear-gradient(90deg, rgba(80,164,251,1) 0%, rgba(0,226,250,1) 100%);
    border: 1px solid #00E2FA;
    border-radius: 5px;
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    line-height: 27px;
    padding: 10px 50px;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
}
@media (max-width: 1000px) {
    .homepublica_haztemiembro .homepublica_repuesto_btn{
        width: 320px;
    }
}
@media (max-width: 800px) {
    .homepublica_haztemiembro_btn{
      width: 100%;
    }
}
@media (max-width: 550px) {
    .homepublica_haztemiembro_btn{
      width: 100%;
      font-size: 12px;
      line-height: 15px;
      padding: 10px 20px;
    }
    .homepublica_haztemiembro .homepublica_repuesto_btn{
        width: 250px;
        font-size: 15px;
        line-height: 20px;
    }
}