.caja_cerrarcnae{
    position: fixed;
    top: 27.5%;
    left: 50%;
    transform: translate(-50%, -20%);
    z-index: 9998;
    cursor: pointer;
    width: 650px;
    height: 850px;
}
.cerrarCnae{
    position: relative;
}
.caja_cerrarcnae .cerrarCnae p{
    position: absolute;
    top: -65px;
    right: 0;
    font-size: 0.65rem;
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;
}
.modalcnae_cerrar:hover{
    color:white;
}
.cerrarCnae p:hover{
    color: white;
}
.modalcnae {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    overflow:auto;
    overflow-x: hidden;
    width: 650px;
    height: 850px;
    padding: 50px;
    background-color: #0B0D1A;
    border: 1px solid #707070;
    border-radius: 5px;
}
.modalcnae_box{
    position: relative;
}
.modalcnae_cerrar{
    position: absolute;
    top:-30px;
    right: -35px;
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;
}
/* width */
.modalcnae::-webkit-scrollbar {
    width: 10px;
}
/* Track */
.modalcnae::-webkit-scrollbar-track {
    background: #f1f1f1; 
}
/* Handle */
.modalcnae::-webkit-scrollbar-thumb {
    background: #888; 
}
/* Handle on hover */
.modalcnae::-webkit-scrollbar-thumb:hover {
    background: #555; 
}
.modalcnae_buscador label{
    font-size: 18px;
}
.modalcnae_buscador input{
    width: 100%;
    padding: 12px;
    margin: 15px 0;
    border-radius: 5px;
}
.modalcnae_cnaelist_item{
    display: flex;
    cursor: pointer;
    margin-bottom: 15px;
}
.modalcnae_cnaelist_item p{
    font-size: 18px;
    margin:0.5rem 0;
}
.modalcnae_cnaelist_item p:first-of-type{
    padding: 1rem;
    color: white;
    border: 1px solid white;
    min-height: 5rem;
    width: 6rem;
    font-size: 18px;
}
.modalcnae_cnaelist_item p:nth-of-type(2){
    padding: 1rem;
    color: #999999;
    border: 1px solid white;
    min-height: 5rem;
    width: 100%;
    font-size: 18px;
}

@media (max-width: 800px) {
    .modalcnae {
        width: 600px;
    }
    .caja_cerrarcnae{
        width: 600px;
    }
  }
@media (max-width: 630px) {
    .modalcnae {
        width: 500px;
    }
    .caja_cerrarcnae{
        width: 500px;
    }
  }
  @media (max-width: 530px) {
    .modalcnae {
        width: 330px;
        padding: 50px 20px;
        top: 5%;
        transform: translate(-50%, 0%);
        max-height: 70vh;
    }
    .modalcnae_cerrar{
        right: 0;
    }
    .caja_cerrarcnae{
        width: 330px;
    }
    .caja_cerrarcnae .cerrarCnae p{
        top: -60px;
    }
  }