.item-canal{
    background: #262632 0% 0% no-repeat padding-box;
    border: 2px solid #262632;
    box-shadow: 0px 0px 20px #00000065;
    border-radius: 10px;
    max-width: 100%;
    text-align: center;
    padding: 60px 30px;
    display: block;
    cursor: pointer;
}
  
.item-canal:hover{
box-shadow: 0px 0px 25px #58A8F5;
border: 2px solid #27E1F8CC;
opacity: .8;
}
  

.item-canal img {
    width: 100%;
    height: auto;
}



