.btn {
  background: rgb(80,164,251);
  border: 1px solid #00E2FA;
  border-radius: 5px;
  display: inline-block;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  line-height: 27px;
  padding: 10px 50px;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
}

.btn.principal {
  background: rgb(80,164,251);
  background: linear-gradient(90deg, rgba(80,164,251,1) 0%, rgba(0,226,250,1) 100%);
  border: 1px solid #00E2FA;
}

.btn.secundario {
  background: #000;
  border: 1px solid #FFFFFF;
}

.btn.transparente {
  background: transparent;
  border: 1px solid #FFFFFF;
}

