
.caja-info {
    min-width: 200px;
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.caja-info img {
    max-width: 100px;
}

.caja-info .titulo {
    margin-bottom: 5px;
    font-size: 22px;
    text-align: center;
}

.caja-info .subtitulo {
    font-size: 16px;
    text-align: center;
    line-height: 20px;
}