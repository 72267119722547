.notfound{
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(#000000, #222232);
    height: 100vh;
    color: white;
}
.notfound_content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.notfound_content h2{
    font-weight: 500;
    font-size: 45px;
    margin-bottom: 50px;
}
.notfound_content h2 span{
    color: #00E1FA;
}
.notfound_content p{
    width: 60%;
    text-align: center;
    font-size: 27px;
    line-height: 38px;
    margin-bottom: 50px;
}
.notfound_content p a{
    color: #00E1FA;
    text-decoration: underline;
}