.legal{
    min-height: 100vh;
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(34,34,50,1) 100%);
}
.legal h1{
    margin-top:0;
}
.legal_content{
    padding:5% 15%;
}
.legal_content img, .legal_content svg{
    width: 13rem;
}
.legal_content h2{
    color:white;
    font-size: 2rem;
    font-weight:600;
}
.legal_content_section{
    line-height: 2rem;
}
.legal_content_section h3{
    color:white;
    margin-bottom: 2rem;
}
.legal_content_section p{
    font-size: 1rem;
    margin-bottom: 2rem;
}
@media (max-width: 500px) {
    .legal_content{
        padding:5% 10%;
    }
    .legal_content_section h3{
        margin-bottom: 1rem;
    }
    .legal_content_section p{
        margin-bottom: 1rem;
    }
}
@media (max-width: 400px) {
    .legal_content{
        padding:5% 7%;
    }
}