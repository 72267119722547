.cardEvento {
    position: relative;
    background: #000000;
    border-radius: 5px;
    box-shadow: 0px 0px 20px #00000065;
    text-align: left;
    overflow: hidden;
    width: 360px;
    height: 390px;
    margin-right:1.5rem
}

.cardEvento-titulo{
    cursor: pointer;
    font-size: 18px;
    font-family: 'Montserrat', sans-serif ;
    font-weight: 500;
    min-height: 70px;
    line-height: 1.5rem;
}
.cardEvento-titulo a{
    color: #ddd;
}

.cardEvento-contendor-imagen{
    flex-wrap: wrap;
    overflow: hidden;
    display: flex;
    margin-bottom: 20px;
}


.cardEvento-contendor-imagen .cardEvento-etiqueta {
    opacity: .9;
    position: absolute;
    margin: 10px;
}

.cardEvento-imagen{
    width: 100%;
    height: 203px;
}
.cardEvento-imagen img{
    object-fit: cover;
    height: 203px;
}

.cardEvento-body {
    margin-bottom: 10px;
    padding: 0 20px;
}

.cardEvento-contendor-imagen .cardEvento-etiqueta{
    color:#000000B3;
}

.cardEvento-etiquetas {
    display: flex;
    margin: 18px 0;
    flex-wrap: wrap;
}

.cardEvento-etiqueta{
    background: #CCCCCC;
    color: #000000;
    font-size: 12px;
    margin-right: 10px;
    padding: 2px 6px;
    border-radius: 3px;
    margin-bottom: 10px;
    letter-spacing: 0.4px;
}
.etiqueta-grey{
    background-color: #333333;
    color: #999999;
}

.cardEvento-etiqueta.dark{
    font-size: 12px;
    font-weight: 400;
    padding: 4px 12px;
    background: rgba(0, 0, 0, .75);
    color: white;
    letter-spacing: 0.4px;
}

.cardEvento-footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    padding: 10px 20px 10px 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.cardEvento-like{
    cursor: pointer;
}

.cardEvento-fecha{
    font-size: 15px;
    font-family: 'Montserrat', sans-serif ;
    max-width: 80%;
    font-weight: 400;
} 
.disponible{
    color: #DDDDDD; 
}
.directo{
    color:#FA1900;
    font-weight: 600;
}
@media (max-width: 500px) {
    .container-carrusel {
        margin: 4rem 2rem;
    }
    .cardEvento {
        width: 270px;
        height: 360px;
    }
    .cardEvento-imagen{
        height: auto;
    }
    .cardEvento-imagen img {
        height: auto;
    }
    .cardEvento-titulo{
        font-size: 14px;
        min-height: 95px;
    }
    .cardEvento-etiqueta{
        font-size: 10px;
    }
    .cardEvento-fecha{
        font-size: 12px;
    } 
}

@media (max-width: 768px) {
    .container-carrusel {
        margin: 20px;
    }
    .container-carrusel .titulo{
        font-size: 28px;
        line-height: 32px;
        margin-bottom: 20px;
      }
}

