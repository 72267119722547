.caja-item {
    background: #000000;
    border-radius: 5px;
    box-shadow: 0px 0px 20px #00000065;
    text-align: left;
    overflow: hidden;
    max-width: 350px;
}

.caja-item-titulo{
    color: #DDDDDD;
    cursor: pointer;
    font-size: 14px;
    font-family: 'Montserrat', sans-serif ;
    font-weight: 600;
    min-height: 54px;
}

.caja-item-contendor-imagen{
    flex-wrap: wrap;
    overflow: hidden;
    position: relative;
    display: flex;
    margin-bottom: 20px;
}


.caja-item-contendor-imagen .caja-item-etiqueta {
    opacity: .9;
    position: absolute;
    margin: 10px;
}

.caja-item-imagen{
    width: 360px;
    height: 173px;
    max-width: 360px;
}

.caja-item-body {
    margin-bottom: 10px;
    padding: 0 20px;
}

.caja-item-contendor-imagen .caja-item-etiqueta{
    color:#000000B3;
}
.caja-item-etiqueta{
    background: #CCCCCC;
    color: #000000;
    font-size: 12px;
    margin-right: 10px;
    padding: 2px 3px;
    border-radius: 3px;
    margin-bottom: 10px;
}

.caja-item-etiqueta.dark{
    background: #333333;
    color: #999999;
}

.caja-item-footer {
    padding: 10px 20px 10px 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.caja-item-like{
    cursor: pointer;
}

.caja-item-fecha{
    color: #DDDDDD;
    font-size: 12px;
    font-family: 'Montserrat', sans-serif ;
} 
.disponible{
    color: #DDDDDD; 
}

