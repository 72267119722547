.recuperarPassword{
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: rgba(11, 13, 26, .80);
    z-index: 3;
}
.recuperarPasswordModal{
    position: relative;
    width: 480px;
    max-width: 90vw;
    padding: 50px;
    background-color: #0B0D1A;
    border:1px solid #707070;
    box-sizing: content-box;
    border-radius: 5px;
}
.recuperarPasswordModal .recuperarPassword_cerrar{
    position: absolute;
    top:-28px;
    right: 0;
    font-size: 0.65rem;
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;
}
.recuperarPasswordModal_logo{
    color: white;
    text-align: center;
    margin-bottom: 3rem;
}
.recuperarPasswordModal_logo span{
    color:#00E2FA;
    margin-left: 0.15rem;
}
.recuperarPasswordModal h2{
    color: white;
    font-size: 1.5rem;
    margin-bottom: 20px;
}
.recuperarPassword_form button{
    padding: 1.2rem;
    width: 100%;
    border:1px solid #999999;
    border-radius: 5px;
    background-color: #0B0D1A;
    color: #999999;
    text-transform: uppercase;
    font-size: 0.95rem;
    font-weight: 600;
    letter-spacing: 1px;
    text-align: center;
    cursor: pointer;
}
.recuperarPassword_form input{
    height: 60px;
    background: #707070;
    border-radius: 5px;
    border: none;
    width: 100%;
    margin: 10px 0;
    color: #fff;
    font-size: 16px;
    padding: 0 50px 0 15px;
}
