.grid-container {
  display: flex;
  margin-top: 80px;
  margin-bottom: 30px;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-left: -20px;
  margin-right: -20px;
}

.grid-container  .grid-item{
    
  text-align: center;
  max-width: 360px;
  margin: 20px;
}

 
@media (min-width: 575.98px) { 
  .grid-container {
    display: flex;
    margin-top: 80px;
    margin-bottom: 30px;
    flex-wrap: wrap;
  }  

}

@media (min-width: 767.98px) { 
  .grid-container {
    display: flex;
    margin-top: 80px;
    margin-bottom: 30px;
    flex-wrap: wrap;
  }  
}

@media (min-width: 991.98px) { 
  .grid-container {
    display: flex;
    margin-top: 80px;
    margin-bottom: 30px;
    flex-wrap: wrap;
  }
  
}

@media (min-width: 1199.98px) { 
  .grid-container {
    display: flex;
    margin-top: 80px;
    margin-bottom: 30px;
    flex-wrap: wrap;
  }
  
}

@media (min-width: 1399.98px) { 
  .grid-container {
    display: flex;
    margin-top: 80px;
    margin-bottom: 30px;
    flex-wrap: wrap;
  }
  
}

@media (min-width: 1700px) { 
  .grid-container  .grid-item{

    margin: 25px 33px;
  }
  
  
}

  