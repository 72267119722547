.bannerInterior {
  position: relative;
  background-color: #000;
}
.BannerInterior_background img{
  height: 504px;
  object-fit: cover;
  vertical-align: bottom;
}
.banner-interior.BannerInterior .BannerInterior_background img{
  height: 504px;
  object-fit: cover;
  vertical-align: bottom;
  object-position: left;
}
.BannerInterior_content {
  position: absolute;
  top: 100px;
  left: 150px;
  width: 600px;
}
.banner-interior.BannerInterior.BannerInterior_content {
  top: 50px;
}

.BannerInterior_content img{
  width: 325px;
  margin-bottom: 50px;
}
.BannerInterior_content .titulo{
  color: #FFFFFF;
  font-size: 38px;
  font-weight: 600;
  line-height: 49px;
  margin-bottom: 50px;
}
.BannerInterior_content .subtitulo{
    color: #999999;
    font-size: 19px;
    line-height: 29px;
    font-weight: 600;
    margin-bottom: 50px;
}
.BannerInterior_btn{
  width: 446px;
  background: linear-gradient(90deg, rgba(80,164,251,1) 0%, rgba(0,226,250,1) 100%);
  border: 1px solid #00E2FA;
  border-radius: 5px;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  line-height: 27px;
  padding: 10px 50px;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
}
@media (max-width: 1200px) {
  .BannerInterior_background img{
    object-position: 80%;
  }
}
@media (max-width: 800px) {
  .BannerInterior_content {
    top: 10%;
    left: 10%;
    width: 80%;
  }
}
@media (max-width: 600px) {
  .BannerInterior_background img{
    object-position: 70%;
  }
  .banner2 .item-etiqueta{
    margin-bottom: 5px;
  }
  .BannerInterior_content {
    top: 2%;
  }
  .BannerInterior_content img{
    width: 200px;
    margin-bottom: 25px;
  }
  .BannerInterior_content .titulo{
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 25px;
  }
  .BannerInterior_content .subtitulo{
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 25px;
  }
  .BannerInterior_btn{
    width: 90%;
    font-size: 12px;
    line-height: 15px;
    padding: 10px 20px;
  }
}


.banner2{
  height:504px
}
.banner2 .item-etiqueta{
  background: #CCCCCC;
  color: #000000;
  font-size: 13px;
  margin-right: 5px;
  padding: 3px 4px;
  border-radius: 3px;
}
.banner2 .etiqueta_tipo{
  background:#262632;
  color: #999999;
}
.banner2 .etiqueta_categoria{
  background: #000000;
  color: #CCCCCC;
}
.banner2 .titulo{
  font-size: 38px;
  font-weight: 500;
  margin-bottom: 10px;
}
.Destacado.fuente-x2 {
   font-size: 38px; 
   margin-left: -30px;
}
.Destacado.azul {
  color: #00e1fa;
}
.banner2 .titulo.fuente-x2{
  font-size: 60px;
  line-height: 70px;
  margin-left: -30px;
  
}


.banner2 .destacado{
  margin-bottom: 20px;
}
.banner2_btn{
  background: #000;
  border: 1px solid #FFFFFF;
  border-radius: 5px;
  display: inline-block;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  line-height: 27px;
  padding: 10px 50px;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
}
.BannerInterior .homepublica_repuesto_btn{
  position: absolute;
  bottom:60px;
  left: 150px;
  z-index: 10;
}
@media (max-width: 800px) {
  .BannerInterior .homepublica_repuesto_btn {
    left: 10%;
  }
  .banner2 .titulo{
    font-size:28px;
    font-weight: 500;
    margin-bottom: 10px;
    line-height: 32px;
  }
  .banner2 .titulo.fuente-x2{
    font-size:28px;
    line-height: 32px;
    margin-left: 0px;
    
  }
  .Destacado.fuente-x2 {
    font-size: 18px; 
    margin-left: 0px;
  }
  .banner2_btn{
    font-size: 18px;
    line-height: 24px;
    padding: 8px 50px;
   
  }
}
@media (max-width: 600px) {
  .BannerInterior .homepublica_repuesto_btn {
    width: 350px;
    bottom:120px;
  }
}
@media (max-width: 425px) {
  .BannerInterior .homepublica_repuesto_btn {
    width: 250px;
    bottom:30px;
  }
  .cookiemodal_buttons{
    margin-bottom: 10px;
  }
}

.bannerPrincipal_content.banner3 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 50px 0  50px 0px;
  background: linear-gradient(90deg, rgba(0,4,9,1) 0%, rgba(0,7,9,0.8491771708683473) 65%, rgba(0,8,9,0.6671043417366946) 87%, rgba(0,8,9,0) 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.bannerInterior.e {
  position: relative;
  max-width: 100%;
  overflow: hidden;
  background: #f00;
}

.banner3{
  height: 420px;
}

.banner3 .tituloPrograma {
  color: #00e1fa;
  font-size: 30px;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.banner3 .titulo.fuente-x2 {
  color: #fff;
  font-size: 37px;
  line-height: 40px;
  margin-left: 0px;
  font-weight: 400;
  margin-bottom: 0;
}

.banner3 .titulo.fuente-x2 b {
  font-weight: 600;
}

.banner3 .powered {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.banner3 .powered img.logo {
  max-width: 180px;
  height: auto;
  margin: 0 0 0 30px;
}
.bannerInterior.tipo3 .bannerPrincipal_background img {
  height: 420px;
  vertical-align: bottom;
  object-position: center;
}


.bannerInterior.tipo3 .bannerPrincipal{
  background: #000;
  padding: 0 0%;
  max-width: 100%;
  margin: 0 auto;
}

@media (min-width: 600px) {
  .bannerInterior.tipo3 .bannerPrincipal_background img {
    height: 420px;
    object-fit: cover;
    vertical-align: bottom;
    object-position: center;
  }
  .banner3 .titulo.fuente-x2 {
    color: #fff;
    font-size: 57px;
    line-height: 60px;
    margin-left: 0px;
    font-weight: 400;
    margin-bottom: 0;
  }
}




@media (min-width: 800px) {
  .bannerInterior.tipo3 .bannerPrincipal_background img {
    height: 420px;
    object-fit: contain;
    vertical-align: bottom;
    object-position: right center;
  }

}

@media (max-width: 1199px) {
  .bannerPrincipal_content.banner3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .bannerPrincipal_content.banner3 {
    background: rgb(0,0,0);
    background: -moz-linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 40%, rgba(0,0,0,0) 100%);
    background: -webkit-linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 40%, rgba(0,0,0,0) 100%);
    background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 40%, rgba(0,0,0,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
  }
  .bannerInterior.tipo3 .bannerPrincipal {
    background: #000;
    padding: 0 0%;
    max-width: 752px;
    margin: 0 auto;
  }
}

@media (min-width: 1200px) {
  .bannerPrincipal_content.banner3 {
    background: rgb(0,0,0);
    background: -moz-linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 40%, rgba(0,0,0,0) 100%);
    background: -webkit-linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 40%, rgba(0,0,0,0) 100%);
    background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 40%, rgba(0,0,0,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
  }
  .bannerInterior.tipo3 .bannerPrincipal {
    background: #000;
    padding: 0 0%;
    max-width: 1144px;
    margin: 0 auto;
  }
}


@media (min-width: 1600px) {
  .bannerPrincipal_content.banner3 {
    background: rgb(0,0,0);
    background: -moz-linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 40%, rgba(0,0,0,0) 100%);
    background: -webkit-linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 40%, rgba(0,0,0,0) 100%);
    background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 40%, rgba(0,0,0,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
  }
  .bannerInterior.tipo3 .bannerPrincipal {
    background: #000;
    padding: 0 0%;
    max-width: 1536px;
    margin: 0 auto;
  }
}

@media (max-width: 1200px) {
  .bannerPrincipal_content.banner3 {
    top: 0;
    left: 10%;
    background: rgb(0,4,9);
    background: transparent linear-gradient(180deg, #000000BF 0%, #000000BF 100%) 0% 0% no-repeat padding-box;
    z-index: 99;
    position: relative;
    left: 0;
    width: 100%;
    padding: 0;
    padding-bottom: 0px;
    margin-top: -420px;
    text-align: center;
  }
  .banner3 .powered {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}