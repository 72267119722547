.modalterminos{
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(11, 13, 26, .80);
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* width */
.modalterminos ::-webkit-scrollbar {
    width: 10px;
    margin-top: 30px;
  }
  
  /* Track */
.modalterminos ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
.modalterminos ::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
.modalterminos ::-webkit-scrollbar-thumb:hover {
    background: #555; 
    cursor: pointer;
}

.modal-terminos-content {
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0B0D1A;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    border: 1px solid rgb(182, 182, 182);
    border-radius: 5px;
}

.modalterminos-textbox {
    height: 600px;
    overflow: auto;
    padding: 50px;
}

.modalterminos .btn-cerrar-modal{
    right: 0;
    font-size: 0.65rem;
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
}
.modalterminos .btn-cerrar-modal-end{
    right: 0;
    font-size: 0.65rem;
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
}
.modalterminos_body{
    width: 600px;
    max-height: 600px;
    padding: 50px;
    background-color: #0B0D1A;
    box-sizing: content-box;
    z-index: 20;
    height: 600px;
}
.modalterminos_body ul li:before{
    content: "";
}
.modalterminos_body h2{
    color: white;
    font-size: 38px;
    color: white;
    margin-bottom: 30px;
}
.modalterminos_body ul li{
    font-size: 0.9rem;
    margin-bottom: 1rem;
    font-weight: 400;
    list-style-type: disc!important;
    color:#999;
}
.modalterminos_body p{
    font-size: 0.9rem;
    font-weight: 400;
    color: #999999;
    margin-bottom: 29px;
}
.modalterminos_body p b{
    font-size: 0.9rem;
    font-weight: 600;
    color: white;
    margin-bottom: 29px;
}
.modalterminos_body h3{
    font-size:20px;
    font-weight: 600;
    color: white;
    margin-bottom: 29px;
}
/* width */
.modalterminos_body::-webkit-scrollbar {
    width: 10px;
}

/* Track */
.modalterminos_body::-webkit-scrollbar-track {
    background: #f1f1f1; 
}

/* Handle */
.modalterminos_body::-webkit-scrollbar-thumb {
    background: #888; 
}

/* Handle on hover */
.modalterminos_body::-webkit-scrollbar-thumb:hover {
    background: #555; 
}

@media (max-width: 768px) { 
    .modalterminos_body h2{
        font-size: 18px;
    }
    .modalterminos_body p {
        font-size: 12px;
    
    }
  }
  